<template>
  <vs-table class="w-auto relative" ref="table">
    <template #thead>
      <vs-tr>
        <vs-th class="!w-2">
          <i class="bx bx-check" />
        </vs-th>
        <vs-th class="w-1/4"> Creator </vs-th>
        <vs-th> Key </vs-th>
        <vs-th> Amount </vs-th>
        <vs-th class="w-full"> Date</vs-th>
      </vs-tr>
    </template>
    <template #tbody v-if="wallets">
      <vs-tr :key="i" v-for="(wl, i) in wallets.docs" :data="wl">
        <vs-td>
          <i v-if="!checkValid(wl)" class="bx bx-x"></i>
          <i v-else class="bx bx-check"></i>
        </vs-td>
        <vs-td>
          {{ wl.creator.username }}
        </vs-td>
        <vs-td class="w-1/3">
          {{ wl.key }}
        </vs-td>
        <vs-td> {{ wl.amount }} $ </vs-td>
        <vs-td class="!w-1/5">{{ wl.createdAt | formatTimestamp }} </vs-td>
      </vs-tr>
    </template>
    <template #footer v-if="wallets">
      <vs-pagination v-model="page" :length="wallets.totalPages" />
    </template>
  </vs-table>
</template>

<script>
import { getWallets } from "@/services/ApiService";

export default {
  name: "WalletTable",
  data: () => ({
    page: 1,
    max: 10,
    wallets: [],
  }),
  methods: {
    async getWallets() {
      const loading = this.$vs.loading({
        target: this.$refs.table,
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
        type: "points",
      });
      this.wallets = await getWallets(this.page)
        .then((response) => response)
        .catch(() => {
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: "Something went wrong!",
          });
          loading.close();
        });
      loading.close();
    },
    checkValid(wallet) {
      return Boolean(wallet.valid);
    },
  },
  async created() {
    await this.getWallets();
    this.$store.watch(
      (state) => state.wallets,
      (wallets) => {
        if (!wallets) {
          this.getWallets();
        }
        this.wallets = wallets;
      }
    );
  },
  watch: {
    page() {
      this.getWallets();
    },
  },
};
</script>

<style scoped lang="scss">

::v-deep {
  .vs-input-content input {
    width: 100%;
    height: 2.5rem;
  }
}

</style>
