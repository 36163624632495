<template>
  <div class="flex justify-center">
    <div class="w-full flex flex-col gap-4 sm:w-max-2xl">
      <CreateWallet />

      <WalletTable />
    </div>
  </div>
</template>

<script>
import CreateWallet from "@/views/admin/wallet/CreateWallet";
import WalletTable from "@/views/admin/wallet/WalletTable";

export default {
  name: "Wallet",
  components: { CreateWallet, WalletTable },
};
</script>
