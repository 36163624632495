<template>
  <div class="block flex-row">
    <vs-input
      v-model="amount"
      placeholder="Create Wallet"
      class="w-full h-14"
      :loading="isLoading"
      :disabled="isLoading"
    >
      <template v-if="isWalletInvalid && amount !== ''" #message-danger>
        Invalid wallet code!
      </template>
      <template #icon>
        <i class="bx bx-wallet" />
      </template>
    </vs-input>
    <vs-button
      block
      @click="createWallet"
      :disabled="isWalletInvalid || isLoading"
    >
      Create
    </vs-button>
  </div>
</template>

<script>
import { createWallet, getWallets } from "@/services/ApiService";
import { syncUser } from "@/services/UserService";

export default {
  name: "CreateWallet",
  data: () => ({
    amount: "",
    isLoading: false,
    wallets: null,
  }),
  methods: {
    createWallet() {
      this.isLoading = true;
      const walletCode = this.amount;
      createWallet({
        amount: Number(walletCode),
      })
        .then(() => {
          this.isLoading = false;
          this.amount = "";
          this.$vs.notification({
            color: "success",
            name: "Success",
            text: "Wallet code successfully used!",
          });
          syncUser();
          this.getWallets();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: error.response.data.message,
          });
        });
    },
    async getWallets() {
      this.isLoading = true;
      getWallets()
        .then((response) => {
          this.isLoading = false;
          this.$store.commit("setWallets", response);
        })
        .catch((error) => {
          this.isLoading = false;
          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: error.response.data.message,
          });
        });
    },
  },
  computed: {
    balance() {
      return this.$store.state.user?.balance;
    },
    isWalletInvalid() {
      return this.amount > 1000000;
    },
  },
};
</script>

<style scoped lang="scss">

::v-deep {
  .vs-input-content input {
    width: 100%;
    height: 2.5rem;
  }
}

</style>
